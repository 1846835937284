
import Vue from 'vue'
export default Vue.extend({
  name: 'RPHPaymentItem',
  components: {},
  props: {
    type: {
      type: String,
      default: 'visa',
    },
  },
  data() {
    return {
      payments: {
        visa: {
          image: require('icons/visa-sm.png'),
          title: 'visa',
        },
        mastercard: {
          image: require('icons/mastercard-logo.png'),
          title: 'mastercard',
        },
      },
    }
  },
  computed: {},
  methods: {},
})
