
import Vue from 'vue'
export default Vue.extend({
  name: 'RPHBadge',
  props: {
    /**
     * count to show
     */
    count: {
      type: [Number, String],
      required: true,
    },
    /**
     * color of the border
     * could be dark and light
     * @values light, dark, blue
     */
    borderColor: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
