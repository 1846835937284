
import Vue from 'vue'
export default Vue.extend({
  name: 'RPHHeaderBurger',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    handler(e: MouseEvent) {
      this.$emit('click', e)
    },
  },
})
