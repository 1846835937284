
import Vue from 'vue'
import EmblemIcon from '~/assets/icons/input-emblem.svg?inline'
import SearchIcon from '~/assets/icons/search-icon.svg?inline'
import CheckIcon from '~/assets/icons/checked-green.svg?inline'
import PositionIcon from '~/assets/icons/position-icon.svg?inline'
import Eye from '~/assets/icons/eye.svg?inline'

export default Vue.extend({
  name: 'RPHInput',
  components: { EmblemIcon, CheckIcon, PositionIcon, Eye, SearchIcon },
  props: {
    type: { type: String, default: 'text' },
    id: { type: String },
    mask: { type: String, default: '' },
    error: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    inputMode: { type: String, default: 'text' },
    readonly: { type: Boolean, default: false },
    value: { type: String, default: '' },
    isUpperCase: { type: Boolean, default: false },
    emblem: { type: Boolean, default: false },
    bgDark: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    isPosition: { type: Boolean, default: false },
    noUpperCase: { type: Boolean, default: true },
    search: { type: Boolean, default: false },
    maxLength: { type: Number, default: 128 },
    autocomplete: { type: String },
  },
  data() {
    return {
      focused: false,
      isHidden: true,
      type_current: this.type,
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
    passwordAppearance() {
      this.isHidden = !this.isHidden
      if (this.isHidden) this.type_current = 'password'
      else this.type_current = 'text'
    },
    openModal(): void {
      this.$store.dispatch('rph-modals/changeCheckoutModal', { show: true })
    },
    onEnter(): void {
      this.$emit('onEnter', event)
    },
  },
})
