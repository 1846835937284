
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHTags',
  props: {
    showNew: {
      type: Number,
      default: 0,
    },
  },
})
