
import success from 'icons/toast-success.svg?inline'
import error from 'icons/toast-error.svg?inline'
import info from 'icons/toast-info.svg?inline'
import Warn from 'icons/warn-icon-toast.svg?inline'

/**
 * Примитивное уведомление с автоудалением
 * @displayName Notification
 */
export default {
  name: 'RPHCartToast',
  components: {
    success,
    error,
    info,
    Warn,
  },
  props: {
    /**
     * Данные о уведомления
     * @type {object}
     */
    data: {
      type: Object,
      required: true,
    },
    /**
     * Индекс уведомления
     * @type {Number}
     */
    index: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      autoDelete: setInterval(() => {
        this.deleteNotification()
      }, 5000),
    }
  },
  destroyed() {
    clearInterval(this.autoDelete)
  },
  methods: {
    deleteNotification() {
      this.$store.commit('rph-toast/deleteNotification', this.index)
      clearInterval(this.autoDelete)
    },
  },
}
