
import Vue from 'vue'
import facebook from 'icons/socials/facebook.svg?inline'
import vk from 'icons/socials/vk.svg?inline'
import instagram from 'icons/socials/instagram.svg?inline'
import google from 'icons/socials/google.svg?inline'

export default Vue.extend({
  name: 'RPHFooterSocialLink',
  components: {
    facebook,
    vk,
    instagram,
    google,
  },
  props: {
    /**
     * the social link type
     * @values facebook, vk, instagram, google
     */
    social: {
      type: String,
      default: 'facebook',
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
