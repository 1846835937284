
import Vue from 'vue'
import PositionIcon from '~/assets/icons/position-icon.svg?inline'

export default Vue.extend({
  name: 'RPHInputSearchPosition',
  components: { PositionIcon },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      position: [],
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    getLocation() {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
          return
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos)
          },
          (err) => {
            reject(err)
          },
        )
      })
    },
    async setPosition(): Promise<void> {
      const { coords }: any = await this.getLocation()
      const currentCoords: number[] = [coords.latitude, coords.longitude]

      this.$emit('set-coords', currentCoords)
    },
  },
})
