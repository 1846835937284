
import Vue from 'vue'
export default Vue.extend({
  name: 'RPHTextBadge',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
