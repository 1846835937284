
import Vue, { PropType } from 'vue'
import {
  IProductSpecification,
  IProductProducer,
  IProduct,
  ISimilarTable,
} from 'types/DTO/products'

export default Vue.extend({
  name: 'RPHTableProduct',
  components: {},
  props: {
    productSpec: {
      type: Object as PropType<IProductSpecification>,
      default: () => ({}),
    },
    productProducer: {
      type: Object as PropType<IProductProducer>,
      default: () => {},
    },
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
  computed: {
    similar(): ISimilarTable | null {
      if (this.product?.similar && this.product.similar.length) {
        const priceArr = this.$props.product.similar.map((item: IProduct) => {
          return this.$services.productInBasket.getPrice(item).price
        })
        const filterArr = priceArr.filter((item: number) => item > 0)
        return {
          count: this.product.similar.length,
          price: Math.min(...filterArr),
        }
      }
      return null
    },
  },
})
